<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card class="px-5 py-3" style="margin-top:10px">
      <!-- <template v-slot:heading>
            <div class="display-2 font-weight-light">
              <div style="float:left; margin-right:50px">副反応疑い</div>
            </div>
      </template>-->
      <v-card-text>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>検索条件</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="no_from" label="No(from)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="no_to" label="No(to)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="age_from" label="Age(from)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="age_to" label="Age(to)" type="number"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select :items="['男性','女性','不明']" clearable v-model="gender" label="性別"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="3">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="vaccinated_date_from"
                        label="接種日(from)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="vaccinated_date_from"
                      @input="menu = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="vaccinated_date_to"
                        label="接種日(to)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="vaccinated_date_to"
                      @input="menu2 = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="lotList"
                    clearable
                    v-model="selectedLot"
                    label="ロットNo"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="disease" label="症状"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="['関連なし','関連あり','評価不能']"
                    clearable
                    v-model="causual"
                    label="因果関係"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select :items="['重い','重くない']" clearable v-model="severity" label="重篤度"></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="['死亡','後遺症あり','未回復','軽快','回復','不明']"
                    clearable
                    v-model="result"
                    label="転帰内容"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="diff_from" label="発症までの日数(from)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="diff_to" label="発症日までの日数(to)" type="number"></v-text-field>
                </v-col>
               </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          style="margin-top:10px; font-size:12px; text-align:right"
          v-if="search_conditions_text !==''"
        >
          <span>{{search_conditions_text}}</span>
          <v-icon
            id="copy_icon"
            title="検索条件をコピー"
            style="margin-left:5px;margin-right:10px"
            @click="copyURLToClipboard()"
          >mdi-content-copy</v-icon>
          <span>{{" 検索結果 : " + filteredItems.length +"件" }}</span>
          <input id="dummy" style="display:none" />
        </div>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          mobile-breakpoint="0"
          style="white-space: pre;"
          :footer-props="footer_props"
          :loading="items.length === 0"
        >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
          <template v-slot:item="i">
            <!-- Since v-slot:item overrides how each row is rendered, I rebuild the row starting from <tr>. This allows me to add a class to <tr> based on any condition I want (in this case, the calorie count) -->
            <tr>
              <td>{{i.item.no}}</td>
              <td>{{i.item.age}}</td>
              <td>{{i.item.gender}}</td>
              <td>{{i.item.date_vaccinated}}</td>
              <td>{{i.item.date_occurred}}</td>
              <td>{{i.item.vaccine_name}}</td>
              <!-- <td>{{i.item.maker}}</td> -->
              <td>{{i.item.lot_no}}</td>
              <td>{{i.item.PT_name}}</td>
              <td>{{i.item.causual}}</td>
              <td
                :class="{
                      'red lighten-5 red--text': i.item.severity.includes('重い')
              }"
              >{{i.item.severity}}</td>
              <td
                :class="{
                      'red lighten-5 red--text': i.item.result && i.item.result.includes('死亡')}"
              >{{i.item.result}}</td>
              <td>{{i.item.result_date}}</td>
              <td>
                <a target="_blank" :href="getLink(i.item.source)">{{i.item.source}}</a>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<style>
table {
  table-layout: fixed;
}
.v-data-table td {
  white-space: pre-line;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 8px !important;
}
</style>

<script>
import axios from 'axios'
// import * as d3 from 'd3'
import moment from 'moment'
// import apexchart from 'vue-apexcharts'
import VACCINATED_DATE_FROM_INIT from '../../constants'
export default {
  name: 'Dashboard',

  // components: {
  //   apexchart: apexchart,
  // },

  data() {
    return {
      panel: 0,
      search_conditions_text: '',
      no_from: 0,
      no_to: 99999,
      age_from: '',
      age_to: '',
      gender: '',
      diff_from: '',
      diff_to: '',
      vaccinated_date_from: VACCINATED_DATE_FROM_INIT,
      vaccinated_date_to: null,
      menu: false,
      menu2: false,
      disease: '',
      causual: '',
      severity: '',
      selectedLot: '',
      result: '',
      footer_props: {
        'items-per-page-options': [10, 50, 100, 200],
        showFirstLastPage: true,
      },
      headers: [
        {
          sortable: false,
          text: 'no',
          value: 'no',
          width: '65px',
        },
        {
          text: '年齢',
          value: 'age',
          width: '50px',
        },
        {
          sortable: false,
          text: '性別',
          value: 'gender',
          width: '50px',
        },
        {
          text: '接種日',
          value: 'date_vaccinated',
          width: '100px',
        },
        {
          sortable: false,
          text: '発生日',
          value: 'date_occurred',
          width: '100px',
        },
        {
          sortable: false,
          text: 'ワクチン名',
          value: 'vaccine_name',
          width: '120px',
        },
        // {
        //   sortable: false,
        //   text: '製薬会社',
        //   value: 'maker',
        //   width: '10%',
        // },
        {
          text: 'ロットNo',
          value: 'lot_no',
          width: '80px',
        },
        {
          sortable: false,
          text: '症状',
          value: 'PT_name',
          width: '200px',
        },
        {
          sortable: false,
          text: '因果関係',
          value: 'causual',
          width: '80px',
        },
        {
          sortable: false,
          text: '重症度',
          value: 'severity',
          width: '80px',
        },
        {
          sortable: false,
          text: '転帰内容',
          value: 'result',
          width: '80px',
        },
        {
          sortable: false,
          text: '転帰日',
          value: 'result_date',
          width: '100px',
        },
        {
          sortable: false,
          text: '資料参照',
          value: 'source',
          width: '70px',
        },
      ],
      items: [],
      lotList: [],
    }
  },

  computed: {
    filteredItems() {
      var conditions = []
      this.clearSearchConditionText()
      var text = ''
      if (this.no_to < 99999 || this.no_from > 0) {
        if (text !== '') text += ' / '
        text += 'No : '
        if (this.no_from > 0) text += this.no_from + '以上'
        if (this.no_to < 99999) text += this.no_to + '以下'
        conditions.push(this.filterByNo)
      }
      if (this.age_to !== '' || this.age_from !== '') {
        if (text !== '') text += ' / '
        text += '年齢 : '
        if (this.age_from !== '') text += this.age_from + '歳以上'
        if (this.age_to !== '') text += this.age_to + '歳以下'
        conditions.push(this.filterByAge)
      }
      if (this.gender) {
        if (text !== '') text += ' / '
        text += '性別 : '
        text += this.gender
        conditions.push(this.filterByGender)
      }
      if (this.diff_to !== '' || this.diff_from !== '') {
        if (text !== '') text += ' / '
        text += '発症までの日数 : '
        if (this.diff_from !== '') text += this.diff_from + '日以上'
        if (this.diff_to !== '') text += this.diff_to + '日以下'
        conditions.push(this.filterByDiff)
      }
      if (
        this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT ||
        this.vaccinated_date_to !== null
      ) {
        if (text !== '') text += ' / '
        text += '接種日 : '
        if (this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT) {
          text += this.vaccinated_date_from + ' から '
        }
        if (this.vaccinated_date_to !== null) {
          text += this.vaccinated_date_to + ' まで '
        }
        conditions.push(this.filterByVaccinatedDate)
      }
      if (this.disease) {
        if (text !== '') text += ' / '
        text += '症状名 : ' + this.disease + 'を含む'
        conditions.push(this.filterByDisease)
      }
      if (this.causual) {
        if (text !== '') text += ' / '
        text += '因果関係 : ' + this.causual
        conditions.push(this.filterByCausual)
      }
      if (this.severity) {
        if (text !== '') text += ' / '
        text += '重篤度 : ' + this.severity
        conditions.push(this.filterBySeverity)
      }
      if (this.result) {
        if (text !== '') text += ' / '
        text += '転帰内容 : ' + this.result
        conditions.push(this.filterByResult)
      }
      if (this.selectedLot) {
        if (text !== '') text += ' / '
        text += 'ロットNo : ' + this.selectedLot
        conditions.push(this.filterByLot)
      }
      if (conditions.length > 0) {
        text = '検索条件 : [ ' + text + ' ]'
        this.setSearchConditionText(text)
        return this.items.filter(item => {
          return conditions.every(condition => {
            return condition(item)
          })
        })
      }
      return this.items
    },
  },

  mounted() {
    if (this.$route.query.no_from) this.no_from = this.$route.query.no_from
    if (this.$route.query.no_to) this.no_to = this.$route.query.no_to
    if (this.$route.query.age_from) this.age_from = this.$route.query.age_from
    if (this.$route.query.age_to) this.age_to = this.$route.query.age_to
    if (this.$route.query.gender) this.gender = this.$route.query.gender
    if (this.$route.query.diff_from) this.diff_from = this.$route.query.diff_from
    if (this.$route.query.diff_to) this.diff_to = this.$route.query.diff_to
    if (this.$route.query.vaccinated_date_from) {
      this.vaccinated_date_from = this.$route.query.vaccinated_date_from
    }
    if (this.$route.query.vaccinated_date_to) {
      this.vaccinated_date_to = this.$route.query.vaccinated_date_to
    }
    if (this.$route.query.disease_name) {
      this.disease = this.$route.query.disease_name
    }
    if (this.$route.query.severity) this.severity = this.$route.query.severity
    if (this.$route.query.causuality) {
      this.causual = this.$route.query.causuality
    }
    if (this.$route.query.selectedLot) {
      this.selectedLot = this.$route.query.selectedLot
    }
    if (this.$route.query.result) this.result = this.$route.query.result

    this.getJson()
  },

  watch: {
    $route(to, from) {
      this.getJson()
    },
  },

  methods: {
    clearSearchConditionText() {
      this.search_conditions_text = ''
    },
    setSearchConditionText(text) {
      this.search_conditions_text = text
    },
    filterByNo(item) {
      return this.no_from <= item.no && this.no_to >= item.no
    },
    filterByAge(item) {
      if (item.age === '') return false
      var age = Number(item.age.replace('代', ''))
      if (this.age_from === '') return this.age_to >= age
      if (this.age_to === '') return this.age_from <= age

      return this.age_from <= age && this.age_to >= age
    },
    filterByGender(item) {
      return item.gender === this.gender
    },
    filterByDiff(item) {
      if (item.diff === '' || item.diff === '不明') return false
      var diff = Number(item.diff)
      if (this.diff_from === '') return this.diff_to >= diff
      if (this.diff_to === '') return this.diff_from <= diff

      return this.diff_from <= diff && this.diff_to >= diff
    },
    filterByVaccinatedDate(item) {
      var from = ''
      var to = ''
      if (this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT) {
        from = moment(this.vaccinated_date_from).format('yyyy/MM/DD')
      }
      if (this.vaccinated_date_to) {
        to = moment(this.vaccinated_date_to).format('yyyy/MM/DD')
      }
      if (from === '') return to >= item.date_vaccinated
      if (to === '') return from <= item.date_vaccinated
      return from <= item.date_vaccinated && to >= item.date_vaccinated
    },
    filterByDisease(item) {
      return item.PT_name.includes(this.disease)
    },
    filterByCausual(item) {
      return item.causual === this.causual
    },
    filterBySeverity(item) {
      return item.severity === this.severity
    },
    filterByResult(item) {
      return item.result && item.result.includes(this.result)
    },
    filterByLot(item) {
      return item.lot_no === this.selectedLot
    },

    getLink(source) {
      if (this.$route.params.maker === 'moderna') {
        if (source.includes('第74回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000872479.pdf'
        } else if (source.includes('第75回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000884771.pdf'
        } else if (source.includes('第76回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000898936.pdf'
        } else if (source.includes('第77回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000914305.pdf'
        } else if (source.includes('第78回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000928667.pdf'
        } else if (source.includes('第79回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000938135.pdf'
        } else if (source.includes('第80回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000948864.pdf'
        } else if (source.includes('第81回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000961481.pdf'
        } else if (source.includes('第82回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000972953.pdf'
        } else if (source.includes('第83回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000983365.pdf'
        } else if (source.includes('第85回')) {
          return 'https://www.mhlw.go.jp/content/10601000/000998159.pdf'
        } else if (source.includes('第88回')) {
          return 'https://www.mhlw.go.jp/content/10601000/001010913.pdf'
        } else if (source.includes('第89回')) {
          return 'https://www.mhlw.go.jp/content/10601000/001024118.pdf'
        } else if (source.includes('第90回')) {
          return 'https://www.mhlw.go.jp/content/10601000/001039675.pdf'
        } else if (source.includes('第92回')) {
          return 'https://www.mhlw.go.jp/content/10601000/001068730.pdf'
        } else if (source.includes('第93回')) {
          return 'https://www.mhlw.go.jp/content/10601000/001091336.pdf'
        }
      }

      if (source.includes('第74回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000872477.pdf'
      } else if (source.includes('第75回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000884770.pdf'
      } else if (source.includes('第76回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000898935.pdf'
      } else if (source.includes('第77回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000914286.pdf'
      } else if (source.includes('第78回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000928663.pdf'
      } else if (source.includes('第79回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/000938278.pdf'
      } else if (source.includes('第79回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000938134.pdf'
      } else if (source.includes('第80回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/000948888.pdf'
      } else if (source.includes('第80回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000949343.pdf'
      } else if (source.includes('第81回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/000961483.pdf'
      } else if (source.includes('第81回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000961480.pdf'
      } else if (source.includes('第82回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/000972955.pdf'
      } else if (source.includes('第82回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000972952.pdf'
      } else if (source.includes('第83回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/000983367.pdf'
      } else if (source.includes('第83回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000983364.pdf'
      } else if (source.includes('第85回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/000998682.pdf'
      } else if (source.includes('第85回')) {
        return 'https://www.mhlw.go.jp/content/10601000/000998158.pdf'
      } else if (source.includes('第88回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/001010916.pdf'
      } else if (source.includes('第88回')) {
        return 'https://www.mhlw.go.jp/content/10601000/001010912.pdf'
      } else if (source.includes('第89回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/001024111.pdf'
      } else if (source.includes('第89回')) {
        return 'https://www.mhlw.go.jp/content/10601000/001024107.pdf'
      } else if (source.includes('第90回(-4歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/001039673.pdf'
      } else if (source.includes('第90回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/001039674.pdf'
      } else if (source.includes('第90回')) {
        return 'https://www.mhlw.go.jp/content/10601000/001039672.pdf'
      } else if (source.includes('第92回(-4歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/001068695.pdf'
      } else if (source.includes('第92回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/001068726.pdf'
      } else if (source.includes('第92回')) {
        return 'https://www.mhlw.go.jp/content/10601000/001068694.pdf'
      } else if (source.includes('第93回(-4歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/001091333.pdf'
      } else if (source.includes('第93回(5-11歳)')) {
        return 'https://www.mhlw.go.jp/content/10601000/001091335.pdf'
      } else if (source.includes('第93回')) {
        return 'https://www.mhlw.go.jp/content/10601000/001091327.pdf'
      }

      return ''
    },

    getJson() {
      this.items = []
      this.lotList = []
      var jsonFileName = 'pfizer.json'
      if (this.$route.params.maker === 'moderna') jsonFileName = 'moderna.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          this.items = res.data
          for (var row of res.data) {
            if (!this.lotList.includes(row.lot_no)) {
              this.lotList.push(row.lot_no)
            }
          }
          this.lotList.sort()
        }.bind(this),
      )
    },

    copyURLToClipboard() {
      var url = window.location.href.split('?')[0] + '?'

      var param = ''
      if (this.no_to < 99999 || this.no_from > 0) {
        if (this.no_from > 0) param += '&no_from=' + this.no_from
        if (this.no_to < 99999) param += '&no_to=' + this.no_to
      }
      if (this.age_to !== '' || this.age_from !== '') {
        if (this.age_from !== '') param += '&age_from=' + this.age_from
        if (this.age_to !== '') param += '&age_to=' + this.age_to
      }
      if (this.gender !== '') {
        param += '&gender=' + this.gender
      }
      if (this.diff_to !== '' || this.diff_from !== '') {
        if (this.diff_from !== '') param += '&diff_from=' + this.diff_from
        if (this.diff_to !== '') param += '&diff_to=' + this.diff_to
      }
      if (
        this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT ||
        this.vaccinated_date_to !== null
      ) {
        if (this.vaccinated_date_from > VACCINATED_DATE_FROM_INIT) {
          param += '&vaccinated_date_from=' + this.vaccinated_date_from
        }
        if (this.vaccinated_date_to !== null) {
          param += '&vaccinated_date_to=' + this.vaccinated_date_to
        }
      }
      if (this.disease) {
        param += '&disease_name=' + this.disease
      }
      if (this.causual) {
        param += '&causuality=' + this.causual
      }
      if (this.selectedLot) {
        param += 'selectedLot=' + this.selectedLot
      }
      if (this.severity) {
        param += '&severity=' + this.severity
      }
      if (this.result) {
        param += '&result=' + this.result
      }

      // clipboardがhttpでは動作しないため、別のやり方で回避
      // navigator.clipboard.writeText(url + param)
      const newInput = document.createElement('input')
      newInput.type = 'text'
      newInput.value = url + param
      const dummy = document.getElementById('dummy')
      dummy.parentNode.insertBefore(newInput, dummy.nextSibling)
      newInput.select()
      document.execCommand('copy')
      newInput.parentNode.removeChild(newInput)
      document.getElementById('copy_icon').focus()
    },
  },
}
</script>
